<template>
    <div>
        <form action="#">
            <div class="reserve-form-top">
                <input type="text" v-model="user.name"  placeholder="Name">
                <input type="text" v-model="user.phone"  placeholder="Phone">
                <select v-model="user.gender"  class="custom-select mb-3">
                    <option value="" disabled selected >Gender</option>
                    <option value="male">male</option>
                    <option value="female">female</option>
                    <option value="other">other</option>
                </select>
                <input type="text" v-model="user.address"  placeholder="Address">
            </div>

            <div class="reserve-submit mt-20">
                <!-- <button type="submit" @click.prevent="updateInfo()">Update </button> -->
                <v-btn block 
                    style="background-color: #e54c2a" 
                    @click="updateInfo()"
                    :loading="loading" dark
                    >Update
                </v-btn>
            </div>
            <p class="float-center text-center mt-3">
                <router-link :to="{name: 'profile'}"> 
                    <span style="color:#e54c2a">Back To Profile</span> 
                </router-link>
            </p>
        </form>
        <notifications group="update" position="top middle" />
        <!-- snackbar -->
            <v-snackbar v-model="snackbar">
                {{ text }}
                <v-btn color="pink" text  @click="snackbar = false">
                    Close
                </v-btn>
            </v-snackbar>
        <!-- snackbar -->
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'change-password',
    data () {
        return {
            loading: false,
            text: '',
            snackbar: false,
        }
    }, //end of data
    computed: {
        ...mapGetters({
            user: 'user/GET_USER',
            temp: 'user/GET_TEMP_USER'
        }),

    },

    methods: {
        async updateInfo(){
            if(JSON.stringify(this.user) === JSON.stringify(this.temp)){
                this.snackbar = true
                this.text= 'No change...'
            }else{
                this.loading = true
                let message = await this.$store.dispatch("user/ACT_UPDATE_USER", this.user)
                if(message.success == true){
                    this.loading = false
                this.$notify({
                    group: 'update',
                    type: 'success',
                    title: 'Success',
                    text: 'Information  updated successfully',
                });
                }else{
                    this.loading = false
                    this.snackbar = true
                    this.text= message.message
                }
            }
            
        },
    }, //end of methods
    mounted(){
        this.$store.dispatch('user/ACT_USER')
    }
}
</script>
